import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Socket } from 'ngx-socket-io';
import * as jwt_decode from 'jwt-decode';
const commonurl = 'https://booking.embassyridingschool.in';

//const commonurl = 'http://localhost:5000';
@Injectable({
  providedIn: 'root'
})

export class CommonService {
  private emps:any;
  private posts:any;
  public adminvalues:any;
  public riderDetails;
  public token;

  private empsUpdated = new Subject<{ emps; empCount : number}>();
  private postsUpdated = new Subject<{ posts; postCount : number}>();
  private tokenupdate = new Subject<{token}>();
  // private empsUpdated1 = new Subject<{ clubs; empCount : number}>();
  private subjectObj = new Subject<any>();
  private subjectObjs = new Subject<any>();


  sendMessage(message: string) {
      this.subjectObj.next({ id_Event: message });
  }
  getMessage(){
    return this.subjectObj.asObservable();
}



  constructor(private router:Router,private http:HttpClient,private socket: Socket) { }

  public notifyAddedTicket() {
    return this.socket
     .fromEvent("notifyTicketAdded")
 }

 getEmailVerified(id){
   return this.http.get(commonurl+'/embassy/getEmailVerified?id='+id);
 }

 geteventBYID(id){
  return this.http.get(commonurl+'/embassy/geteventBYID?id_event='+id);
}

 

  signin(credentials) {
    console.log(credentials);
    // this.authenticated = true;
    return this.http.post(commonurl+'/embassy/signin',credentials);
    // this.store.setItem("demo_login_status", true);
    // return of({}).pipe(delay(1500));
  }

  CreateRazorpayOrderID(val){
    return this.http.post(commonurl+'/embassy/CreateRazorpayOrderID',val);
  }



  signup(val){
    return this.http.post(commonurl+'/embassy/signup',val);
  }

  paywithpaytm(amt,id){
    var obj = {
      amt : amt,
      id : id
    } 
    return this.http.post(commonurl+'/embassy/paywithpaytm',obj);
  }

  saveTimings(obj){
    return this.http.post(commonurl+'/embassy/saveTimings',obj);
  }

  updateTimings(obj){
    return this.http.post(commonurl+'/embassy/updateTimings',obj);
  }

  resendlink(id,role){
    var obj = {
      role:role,
      id:id
    }
    return this.http.post(commonurl+'/embassy/resendlink',obj);
  }

  getTimings(){
    return this.http.get(commonurl+'/embassy/getTimings');
  }

  getRoles(){
    return this.http.get(commonurl+'/embassy/getRole')
  }

  getRoleList(){
    return this.http.get(commonurl+'/embassy/getRoles')
  }

  otpValid(otp,id){
    return this.http.get(commonurl+'/embassy/otpValid?otp='+otp+'&id='+id);
  }


  saveRiderProfile(obj,id){
    var obj1 = {
      val : obj,
      id : id
    }
    return this.http.post(commonurl+'/embassy/saveRiderProfile',obj1);
  }

  checkpassport(id){
    return this.http.get(commonurl+'/embassy/checkpassport/'+id);
  }

  clubProfile(val,id)
  {
    var obj = {
      val:val,
      id:id
    }
    return this.http.post(commonurl+'/embassy/clubprofile',obj);
  }

  saveSignUp(obj){
    return this.http.post(commonurl+'/embassy/saveSignUp',obj);
  }

  addMembership(val){
    return this.http.post(commonurl+'/embassy/addMembership',val);
  }

  getMembershipList(){
    return this.http.get(commonurl+'/embassy/getMembershipList');
  }

  getHorse(id){
    const uri = commonurl+"/embassy/getHorse/"+id;
    return this.http.get(uri);
  }
  
  addHorse(obj){
    console.log(obj);
    const uri = commonurl+"/embassy/addHorse";
    return this.http.post(uri,obj);
  }
  deleteHorse(obj){
    const uri=commonurl+"/embassy/deleteHorse";
    return this.http.post(uri,obj);
  }

  addClubCoOrdinator(obj)
  {
console.log(obj);
const uri = commonurl+"/embassy/addClubCoOrdinator";
return this.http.post(uri,obj);
  }


  getclubCoOrdinatorDetails(){
    return this.empsUpdated.asObservable();
  }

  getclubCoOrdinatorDetails1(){
    return this.postsUpdated.asObservable();
  }

  getclubCoOrdinator(postsPerPage: number, currentPage: number){
    const queryParams = `?pagesize=${postsPerPage}&page=${currentPage}`;
    console.log(queryParams);
    this.http
      .get<{ message: string; emps: any; maxPosts: number }>(
        commonurl+"/embassy/getclubCoOrdinator" + queryParams
      )
      .pipe(
        map(empData => {
          return {
            emps: empData.emps,
            maxPosts: empData.maxPosts
          };
        })
      )
      .subscribe(transformedPostData => {
        this.emps = transformedPostData.emps;
       
        this.empsUpdated.next({
          emps: [...this.emps],
          empCount: transformedPostData.maxPosts
        });
      });
  }

  getclubCoOrdinator1(id){
    const queryParams = `?id=${id}`;
    console.log(queryParams);
    this.http
      .get<{ message: string; posts: any; maxPosts: number }>(
        commonurl+"/embassy/getclubCoOrdinator1" + queryParams
      )
      .pipe(
        map(postData => {
          return {
            posts: postData.posts,
            maxPosts: postData.maxPosts
          };
        })
      )
      .subscribe(transformedPostData => {
        this.posts = transformedPostData.posts;
       
        this.postsUpdated.next({
          posts: [...this.posts],
          postCount: transformedPostData.maxPosts
        });
      });
  }
  
  editCoOrdinator(obj)
  {
console.log(obj);
const uri = commonurl+"/embassy/editCoOrdinator";
return this.http.post(uri,obj);
  }





  registerEvents(obj){
    console.log(obj);
    return this.http.post(commonurl+'/embassy/registerEvents',obj);
  }


  getregisterEventDetails(){
    return this.empsUpdated.asObservable();
  }

  getregisterEvent(postsPerPage: number, currentPage: number){
    const queryParams = `?pagesize=${postsPerPage}&page=${currentPage}`;
    console.log(queryParams);
    this.http
      .get<{ message: string; emps: any; maxPosts: number }>(
        commonurl+"/embassy/getregisterEvent" + queryParams
      )
      .pipe(
        map(empData => {
          return {
            emps: empData.emps,
            maxPosts: empData.maxPosts
          };
        })
      )
      .subscribe(transformedPostData => {
        this.emps = transformedPostData.emps;
       
        this.empsUpdated.next({
          emps: [...this.emps],
          empCount: transformedPostData.maxPosts
        });
      });
  }


  getEvents(){
    return this.http.get(commonurl+'/embassy/getEvents1');
  }

  getCategory(){
    return this.http.get(commonurl+'/embassy/getCategory');
  }
  selectDates(obj){  
    return this.http.post(commonurl+'/embassy/selectDates',obj);
  }
  selectPrice(obj){
    console.log(obj);
   return this.http.post(commonurl+'/embassy/selectPrice',obj);
  }

  // getHorse(){
  //   const uri = "http://localhost:4001/embassy/getHorse";
  //   return this.http.get(uri);
  // }

  
  // addHorse(obj){
  //   console.log(obj);
  //   const uri = "http://localhost:4001/embassy/addHorse";
  //   return this.http.post(uri,obj);
  // }
  // deleteHorse(obj){
  //   const uri="http://localhost:4001/embassy/deleteHorse";
  //   return this.http.post(uri,obj);
  // }


  addRole(roleobj){
    console.log(roleobj);
    const uri = commonurl+"/embassy/addRole";
    return this.http.post(uri,roleobj);  
  }

  getSelectedEventsData(idevent,idcategory){
    return this.http.get(commonurl+'/embassy/getSelectedEventsData?idevent='+idevent+'&idcategory='+idcategory);
  }

  getSelectedEvents(){
    return this.http.get(commonurl+'/embassy/getSelectedEvents');
  }

  getRole(){
    const uri = commonurl+"/embassy/getRole";
    return this.http.get(uri);
  }
  // getRoles(){
  //   const uri = "http://localhost:4001/embassy/getRoles";
  //   return this.http.get(uri);
  // }
  deleteRole(obj){
    const uri=commonurl+"/embassy/deleteRole";
    return this.http.post(uri,obj);
  }

  saveSubAdmin(obj){
    return this.http.post(commonurl+'/embassy/saveSubAdmin',obj);
  }

  subadminlist(){
    return this.http.get(commonurl+'/embassy/subadminlist');
  }

  submit(obj){
    console.log(obj);
    const uri=commonurl+"/embassy/addFinance";
    return this.http.post(uri,obj);
  }
  getFinanceTeam(){
    const uri = commonurl+"/embassy/getFinanceTeam";
    return this.http.get(uri);
  }

  editFinance(id){
    this.router.navigate(["/demo/user/editFinanceTeam/" + id ]);
  }

  getFinanceById(id){
    const uri=commonurl+"/embassy/getFinanceById/" + id ;
    return this.http.get(uri);
  }

  updateFinance(obj){
    console.log(obj);
    const uri=commonurl+"/embassy/updateFinance";
    return this.http.post(uri,obj);
  }

  deleteFinance(obj){
    const uri=commonurl+"/embassy/deleteFinance";
    return this.http.post(uri,obj);
  }
  // getCategory(){
  //   const uri = "http://localhost:4001/embassy/getCategory";
  //   return this.http.get(uri);
  // }
  addCategory(obj){
    console.log(obj);
    const uri=commonurl+"/embassy/addCategory";
    return this.http.post(uri,obj);
  }
  deleteCategory(obj){
    const uri=commonurl+"/embassy/deleteCategory";
    return this.http.post(uri,obj);
  }
getEvent(){
  const uri = commonurl+"/embassy/getEvents";
  return this.http.get(uri);
  // .map((events: CalendarEvent[]) => {
  //   this.events = events;
  //   return events;
  // });
}
    addEvent(event){
      console.log(event);
      const uri=commonurl+"/embassy/eventAdd";
      return this.http.post(uri,event);
    }
  

    deleteEvent(obj){
      const uri=commonurl+"/embassy/deleteEvent";
      return this.http.post(uri,obj);
    }

    saveEvent(event){
      console.log(event);
      const uri=commonurl+"/embassy/saveEvent";
      return this.http.post(uri,event);
    }
    
    saveAsDrafts(event){
      console.log(event);
      const uri=commonurl+"/embassy/saveAsDrafts";
      return this.http.post(uri,event);
    }
    getDraftEvent(){
      const uri = commonurl+"/embassy/getDraftEvent";
      return this.http.get(uri);
  
    }
    //getPublishEvent
    getPublishEvent(){
      const uri =commonurl+"/embassy/getPublishEvent";
      return this.http.get(uri);
    }
    listView(){
      this.router.navigate(["demo/user/viewCalender" ]);
    }

    gridView(){
      this.router.navigate(["demo/user/gridCalender" ]);
    }

    calenderView(){
      this.router.navigate(["demo/calendar" ]);
    }
    getCategoryByID(obj){
    const uri = commonurl+"/embassy/getCategoryByID";
      return this.http.post(uri,obj);
    }

    // addRider(obj){
    //   return this.http.post("http://localhost:4001/embassy/addRider",obj);
    // }

    getSiginOTP(obj){
     // const queryParams = `?mobile=${mobile}&otp=${otp}`;
      //console.log(queryParams);
      return this.http.post(commonurl+'/embassy/getSiginOTP',obj);
      // this.http
      // .get<{ token }>(
      //   commonurl+"/embassy/getSiginOTP" + queryParams
      // )
      // .pipe(
      //   map(token => {
      //     return {
      //       token: token
           
      //     };
      //   })
      // )
      // .subscribe(Token => {
      //   this.token = Token.token;
       
        
      // });
  } 

  getsigninotpdetail(){
    return this.tokenupdate.asObservable();
  }

      
      // const uri = commonurl+"/embassy/getSiginOTP";
      //   return this.http.get(uri,obj);
      // }
      
  
     
     
     
      
    addSiginOTP(obj){
      
      return this.http.post(commonurl+'/embassy/addSiginOTP',obj);

    }

    
    addRider(obj){
      return this.http.post(commonurl+'/embassy/addRider',obj);
    }

    viewRiderList(postsPerPage: number, currentPage: number,id,role){
      const queryParams = `?pagesize=${postsPerPage}&page=${currentPage}&id=${id}&role=${role}`;
      console.log(queryParams);
      this.http
        .get<{ message: string; emps: any; maxPosts: number }>(
          commonurl+"/embassy/viewRider" + queryParams
        )
        .pipe(
          map(empData => {
            return {
              emps: empData.emps,
              maxPosts: empData.maxPosts
            };
          })
        )
        .subscribe(transformedPostData => {
          this.emps = transformedPostData.emps;
         
          this.empsUpdated.next({
            emps: [...this.emps],
            empCount: transformedPostData.maxPosts
          });
        });
    } 

    viewEventList(postsPerPage: number, currentPage: number,id,role,array){
      const queryParams = `?pagesize=${postsPerPage}&page=${currentPage}&id=${id}&role=${role}&array=${array}`;
      console.log(queryParams);
      var obj = {
        pagesize : postsPerPage,
        page : currentPage,
        id : id,
        role : role,
        array : array
      }
      console.log(obj);
      this.http
        .post<{ message: string; emps: any; maxPosts: number }>(
          commonurl+"/embassy/getEvents",obj
        )
        .pipe(
          map(empData => {
            return {
              emps: empData.emps,
              maxPosts: empData.maxPosts
            };
          })
        )
        .subscribe(transformedPostData => {
          this.emps = transformedPostData.emps;
         
          this.empsUpdated.next({
            emps: [...this.emps],
            empCount: transformedPostData.maxPosts
          });
        });
    } 
  
    viewRiderList1(id){
      const queryParams = `?id=${id}`;
      console.log(queryParams);
      this.http
        .get<{ message: string; emps: any; maxPosts: number }>(
          commonurl+"/embassy/viewRider1" + queryParams
        )
        .pipe(
          map(empData => {
            return {
              emps: empData.emps,
              maxPosts: empData.maxPosts
            };
          })
        )
        .subscribe(transformedPostData => {
          this.emps = transformedPostData.emps;
         
          this.empsUpdated.next({
            emps: [...this.emps],
            empCount: transformedPostData.maxPosts
          });
        });
    } 

    clubEventRegisterView(id,loginid,array)
    {
      var obj = {
        id : id,
        loginid : loginid,
        array : array
      }
      const uri = commonurl+"/embassy/clubEventRegisterView";
      return this.http.post(uri,obj);
    }

    clubEventRegisterView1(id,loginid,array)
    {
      var obj = {
        id : id,
        loginid : loginid,
        array : array
      }
      const uri = commonurl+"/embassy/clubEventRegisterView1";
      return this.http.post(uri,obj);
    }

    viewRiderList2(id){
      const queryParams = `?id=${id}`;
      console.log(queryParams);
      this.http
        .get<{ message: string; emps: any; maxPosts: number }>(
          commonurl+"/embassy/viewRider2" + queryParams
        )
        .pipe(
          map(empData => {
            return {
              emps: empData.emps,
              maxPosts: empData.maxPosts
            };
          })
        )
        .subscribe(transformedPostData => {
          this.emps = transformedPostData.emps;
         
          this.empsUpdated.next({
            emps: [...this.emps],
            empCount: transformedPostData.maxPosts
          });
        });
    } 

    saveEventRegister(obj){
      return this.http.post(commonurl+'/embassy/saveEventRegister',obj);
    }

    viewRider(){
      return this.empsUpdated.asObservable();
    }
    viewEvent()
    {
      return this.empsUpdated.asObservable();
    }

    getSearchValues(val){
      return this.http.post(commonurl+'/embassy/getSearchValues',val)
    }

    getEventCategory(id){
      return this.http.get(commonurl+'/embassy/getEventCategory/'+id);
    }

    getSearchValuesClub(val){
      return this.http.post(commonurl+'/embassy/getSearchValuesClub',val);
    }

    getRiderValue(id){
      const uri = commonurl+"/embassy/getRiderValue/"+id;
      return this.http.get(uri);
    }

    addRiderEventReg(obj)
    {
const uri = commonurl + "/embassy/saveEventRegister";
return this.http.post(uri,obj);
    }

    addRiderEventReg1(obj)
    {
const uri = commonurl + "/embassy/addRiderEventReg";
return this.http.post(uri,obj);
    }

    paywithRzor(obj)
    {
const uri = commonurl + "/embassy/Paywithrazorpay";
return this.http.post(uri,obj);
    }

    getSingleEvent(id){
      return this.http.get(commonurl+'/embassy/getSingleEvent/'+id);
    }
    sendSMS(obj){
      // var obj = {
      //   mobileNumber : mobileNumber,
      //   otpNumber : otpNumber,
      //   templatename: templatename
      // } 
      return this.http.post(commonurl+'/embassy/sendSMS',obj);
    }

    getpaymentstatus(obj)
    {
      return this.http.post(commonurl+'/embassy/getpaymentstatus',obj);
    }

    getEventdate(id)
    {
      const uri = commonurl+"/embassy/getEventdate/"+id;
      return this.http.get(uri);
    } 
    getEventname(id)
    {
      console.log(id);
      const uri = commonurl+"/embassy/getEventname/"+id;
      return this.http.get(uri);
    }

    getNoOfStables(val)
    {
      console.log(val);
      return this.http.get(commonurl+'/embassy/getNoOfStables?event_date='+val)
    }

    viewDraftEventlist(){
      return this.empsUpdated.asObservable();
    }
  
    getDraftEventlist(postsPerPage: number, currentPage: number){
      const queryParams = `?pagesize=${postsPerPage}&page=${currentPage}`;
      console.log(queryParams);
      this.http
        .get<{ message: string; emps: any; maxPosts: number }>(
          commonurl+"/embassy/getregisterEventDraftlist" + queryParams
        )
        .pipe(
          map(empData => {
            return {
              emps: empData.emps,
              maxPosts: empData.maxPosts
            };
          })
        )
        .subscribe(transformedPostData => {
          this.emps = transformedPostData.emps;
         
          this.empsUpdated.next({
            emps: [...this.emps],
            empCount: transformedPostData.maxPosts
          });
        });
    }
    getCategorylist(id){
      // return this.http.get(commonurl+'/embassy/getCategory');
  
  
      const uri = commonurl+"/embassy/getCategorylist/"+id;
      return this.http.get(uri);
  
    }

    emailexists(email){
      return this.http.get(commonurl+'/embassy/emailexists/'+email)
    }

    emailexists1(email){
      return this.http.get(commonurl+'/embassy/emailexists1/'+email)
    }
  
    mobileexists(mobile){
      return this.http.get(commonurl+'/embassy/mobileexists/'+mobile)
    }




    getTerms(){
      return this.http.get(commonurl+"/embassy/getTerms");
    }
    getAddress(){
      return this.http.get(commonurl+"/embassy/getAddress");
    }
  
    // registerEvents(obj){
    //   console.log(obj);
    //   return this.http.post(commonurl+'/embassy/registerEvents',obj);
    // }
  
    saveTerms(id){
      return this.http.post(commonurl+'/embassy/saveTerms',id);
    }
    updateTerms(id){
      return this.http.post(commonurl+'/embassy/updateTerms',id);
    }
  
    saveAddress(id){
      return this.http.post(commonurl+'/embassy/saveAddress',id);
    }
    updateAddress(id){
      return this.http.post(commonurl+'/embassy/updateAddress',id);
    }
    getRideritems()
    {

    }

    getUsersList(id_event,page,array){
      var obj = {
        id_event : id_event,
        page : page,
        array : array
      }
      return this.http.post(commonurl+'/embassy/getUsersList',obj);
    }

    getMyEvents(id,role)
    {
      return this.http.get(commonurl+'/embassy/getMyEvents?id='+id+'&role='+role)
    }

    getMyEvents1(id,role,page)
    {
      return this.http.get(commonurl+'/embassy/getMyEvents1?id='+id+'&role='+role+'&page='+page);
    }

    getHorseList(id,role){
      return this.http.get(commonurl+'/embassy/getHorseList?id='+id+'&role='+role)
    }


    horseCount(id){
      console.log(id);
           return this.http.get(commonurl+'/embassy/horseCount/' + id);
         }
     
         RegisteredCount(id,role){
     console.log(id);
     return this.http.get(commonurl+'/embassy/RegisteredCount?id='+id+'&role='+role);
         }
     
         upcomingCount(){
           return this.http.get(commonurl+'/embassy/upcomingCount/')
         }
         upcominglist(){
           return this.http.get(commonurl+'/embassy/upcominglist/')
         }
         eventsparticipated(id,role){
           return this.http.get(commonurl+'/embassy/eventsparticipated?id='+id+'&role='+role)
         }
         eventsparticipatedview(){
           return this.http.get(commonurl+'/embassy/eventsparticipatedview/')
         }
         ridersProfile(id){
           return this.http.get(commonurl+'/embassy/ridersProfile/' + id)
     
         }
         getallhorse(){
           this.router.navigate(["/demo/horse/horseview" ]);
         }
     
         getallriders(){
           this.router.navigate(["/demo/rider/viewrider" ]);
     
         }
        //  getMyEvents(id,role)
        //  {
        //    return this.http.get(commonurl+'/embassy/getMyEvents?id='+id+'&role='+role)
        //  }
     
        //  getHorseList(id,role){
        //    return this.http.get(commonurl+'/embassy/getHorseList?id='+id+'&role='+role)
        //  }
        localdata(){
          var decoded = jwt_decode(localStorage.getItem('login'));
          return decoded['res4'];    
        }
     
         addMyEvent(){
           this.router.navigate(["/demo/rider/addrider" ]);
     
         }
         registerEvent(){
           this.router.navigate(["/demo/clubCo-ordinator/riderEventRegister" ]);
         }
         viewParticipatingMembers(id)
         {
           const uri = commonurl+"/embassy/viewParticipatingMembers/"+id;
           return this.http.get(uri);
         }

         getFilterValues(val){
           console.log(val);
           return this.http.post(commonurl+'/embassy/getFilterValues',val);
         }

         getStatus(){
           return this.http.get(commonurl+"/embassy/getStatus");
         }

         getStable()
         {
         
           return this.http.get(commonurl+"/embassy/getStables");
         }
         updateStable(value)
         {

return this.http.post(commonurl+'/embassy/updateStable',value);
         }
         horseStatus(id)
         {

const uri = commonurl+"/embassy/horseStatus/"+id;
return this.http.get(uri);
         }
         riderStatus(id)
         {
const uri = commonurl+"/embassy/riderStatus/"+id;
return this.http.get(uri);
         }

         getClubDetails(id){
           return this.http.get(commonurl+'/embassy/getClubDetails/'+id);
         }

         getRiderDetails(id,role){
          return this.http.get(commonurl+'/embassy/getRiderDetails?id='+id+'&role='+role);
         }

         forgotEmail(id){
           return this.http.post(commonurl+'/embassy/forgotEmail',id)
         }




         JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel,fileName) {
          //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
          var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
          
          var CSV = '';    
       
          if (ShowLabel) {
              var row = "";
         
              for (var index in arrData[0]) {
             
                  row += index + ',';
              }
        
              row = row.slice(0, -1);
              
         
              CSV += row + '\r\n';
          }
          
      
          for (var i = 0; i < arrData.length; i++) {
              var row = "";
            
              for (var index in arrData[i]) {
                  row += '"' + arrData[i][index] + '",';
              }
        
              row.slice(0, row.length - 1);
         
              CSV += row + '\r\n';
          }
        
          if (CSV == '') {        
              alert("Invalid data");
              return;
          }   
        
          fileName += ReportTitle.replace(/ /g,"_");   
          
          var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
         
          var link = document.createElement("a");    
          link.href = uri;
        
          link.download = fileName + ".csv";
      
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }

        saveEventType(fd){
          return this.http.post(commonurl+'/embassy/saveEventType',fd);
        }

        getEventType(){
          return this.http.get(commonurl+'/embassy/getEventType');
        }

        getEditEvent(id){
          return this.http.get(commonurl+'/embassy/getEditEvent/'+id);
        }

        editEvent(obj){
          console.log(obj);
          return this.http.post(commonurl+'/embassy/editEvent',obj);
        }

        disableEvent(id){
          return this.http.get(commonurl+'/embassy/disableEvent/'+id)
        }

        duplicateEvent(obj){
          return this.http.post(commonurl+'/embassy/duplicateEvent',obj);
        }

        userProfile(id,role){
          return this.http.get(commonurl+'/embassy/userProfile/?id='+id+'&role='+role);
        }

        updateProfile(obj){
          return this.http.post(commonurl+'/embassy/updateProfile',obj);
        }

        getUserRoles(){
          return this.http.get(commonurl+'/embassy/getUserRoles');
        }

        ViewEventRegister(id,role)
        {
return this.http.get(commonurl+'/embassy/ViewEventRegister/?id='+id+'&role='+role);
        }
        ReinsertEventRegCategory(obj)
        {
            return this.http.post(commonurl+'/embassy/ReinsertEventRegCategory',obj)
        }
        deleteRegCategory(obj)
        {
          console.log(obj);

return this.http.post(commonurl+'/embassy/deleteRegCategory',obj)
        }

        ReupdateEventRegCategory(obj)
        {
          console.log(obj);
          return this.http.post(commonurl+'/embassy/ReupdateEventRegCategory',obj)
        }

        getProspectus(id){
          return this.http.get(commonurl+'/embassy/getProspectus/'+id);
        }

        getCategorywise(eventid,id){
          return this.http.get(commonurl+'/embassy/getCategorywise?eventid='+eventid+'&id='+id);
        }
        getAllCategorywise(eventid){
          return this.http.get(commonurl+'/embassy/getAllCategorywise?eventid='+eventid);
        }

        reRegisterCheck(idevent,userid,role){
          return this.http.get(commonurl+'/embassy/reRegisterCheck?idevent='+idevent+'&userid='+userid+'&role='+role);
        }

        paywithpaytmresponse(){
          this.http.get(commonurl+'/embassy/message').subscribe(res=>{
            console.log(res);
          })
        }

        getSidebar(id){
          return this.http.get(commonurl+'/embassy/getSidebar/'+id);
        }

        getSideBarValues(){
          return this.http.get(commonurl+'/embassy/getSideBarValues');
        }

        importRiderProfile(fdobject)
       {
         console.log(fdobject);
         return this.http.post(commonurl+'/embassy/importRiderProfile',fdobject)
       }

       clubNameList()
       {
         return this.http.get(commonurl+'/embassy/clubNameList');
       }

       formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
      
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
      
        return [year, month, day].join('-');
      }

      updateDoubleAmount(obj){
        return this.http.post(commonurl+'/embassy/updateDoubleAmount',obj);
      }
       
       importClublProfile(fdobject)
       {
        return this.http.post(commonurl+'/embassy/importClubProfile',fdobject)
       }

       importHorseProfile(fdobject)
       {
        return this.http.post(commonurl+'/embassy/importHorseProfile',fdobject)
       }


       updateRiderEventRegistration(obj){
         return this.http.post(commonurl+'/embassy/updateRiderEventRegistration',obj);
       }

       getAllDashboardNoOfCount(){
        return this.http.get(commonurl+'/embassy/getAllDashboardNoOfCount');
       }

       getMonthBasedData(val){
         var obj = {
           val : val
         }
         return this.http.post(commonurl+'/embassy/getMonthBasedData',obj);
       }

       getAllCurrentEvents(){
         return this.http.get(commonurl+'/embassy/getAllCurrentEvents');
       }

      //  getAllEvents(){
      //    return this.http.get(commonurl+'/embassy/getAllEvents');
      //  }

       updateRiderEventRegistration1(obj){
        return this.http.post(commonurl+'/embassy/updateRiderEventRegistration1',obj);
      }

      getEventListPublished(){
        return this.http.get(commonurl+'/embassy/getEventListPublished')
      }

      getNoOfStables1(val){
        return this.http.post(commonurl+'/embassy/getNoOfStables1',val);
      }

       getRiderDetailsforInvoice(id){
        const uri = commonurl+"/embassy/getRiderDetailsforInvoice/"+id;
        return this.http.get(uri);
      }
      getClubProfileInvoice(id){
        const uri = commonurl+"/embassy/getClubDetailsInvoice/"+id;
        return this.http.get(uri);
      }

      eventListsOfItems()
      {
        return this.http.get(commonurl+'/embassy/eventListsOfItems');
      }
      categoryListsOfItems()
      {
        return this.http.get(commonurl+'/embassy/categoryListsOfItems');

      }


      getRegisterEventListDetails(){
        return this.empsUpdated.asObservable();
      }
    
      editRider(val){
        return this.http.post(commonurl+'/embassy/editRider',val);
      }
    
      getRegisterEventList(event, category, paid,postsPerPage,currentPage){
        // const queryParams = `?event=${event}&category=${category}&paid=${paid}`;
        // console.log(queryParams);
        var queryParams = {
          event : event,
          category : category,
          paid : paid,
          pagesize: postsPerPage,
          page:currentPage
        }
        this.http
          .post<{ message: string; emps: any; maxPosts: number }>(
            commonurl+"/embassy/getRegisterEventList" , queryParams
          )
          .pipe(
            map(empData => {
              return {
                emps: empData.emps,
                maxPosts: empData.maxPosts
              };
            })
          )
          .subscribe(transformedPostData => {
            this.emps = transformedPostData.emps;
           
            this.empsUpdated.next({
              emps: [...this.emps],
              empCount: transformedPostData.maxPosts
            });
          });
      }
    
      

      getRegisterEventListDetails2(){
        return this.postsUpdated.asObservable();
      }
    
   
    
      getRegisterEventList2(event, category, paid,postsPerPage,currentPage){
        // const queryParams = `?event=${event}&category=${category}&paid=${paid}`;
        // console.log(queryParams);
        var queryParams = {
          event : event,
          category : category,
          paid : paid,
          pagesize: postsPerPage,
          page:currentPage
        }
        this.http
          .post<{ message: string; posts: any; maxPosts: number }>(
            commonurl+"/embassy/getRegisterEventList2" , queryParams
          )
          .pipe(
            map(postData => {
              return {
                posts: postData.posts,
                maxPosts: postData.maxPosts
              };
            })
          )
          .subscribe(transformedPostData => {
            this.posts = transformedPostData.posts;
            console.log(this.posts);
            this.postsUpdated.next({
              posts: [...this.posts],
              postCount: transformedPostData.maxPosts
            });
          });
      }
      getUpcomingEvents1(currentPage)
      {
       return this.http.get(commonurl+'/embassy/getUpcomingEvents1?page='+currentPage);
      }

      getCompletedEvent(currentPage)
      {
        return this.http.get(commonurl+'/embassy/getCompletedEvent?page='+currentPage);
      }



      
      getRegisterEventListDashboard(event, category, paid,currentPage){
        // const queryParams = `?event=${event}&category=${category}&paid=${paid}`;
        // console.log(queryParams);
        var queryParams = {
          event : event,
          category : category,
          paid : paid,
     
          page:currentPage
        }
        return this.http
          .post(commonurl+"/embassy/getRegisterEventListDashboard" , queryParams);
          
      }

      getRegisterEventListDashboardAll(date,event, category, paid,currentPage){
        // const queryParams = `?event=${event}&category=${category}&paid=${paid}`;
        // console.log(queryParams);
        var queryParams = {
          date:date,
          event : event,
          category : category,
          paid : paid,
          page:currentPage
        }
        return this.http
          .post(commonurl+"/embassy/getRegisterEventListDashboardAll" , queryParams);
          
      }

      getRegisterEventListDashboard2All(date,event, category, paid,currentPage){
        // const queryParams = `?event=${event}&category=${category}&paid=${paid}`;
        // console.log(queryParams);
        var queryParams = {
          date:date,
          event : event,
          category : category,
          paid : paid,
          page:currentPage
        }
        return this.http.post(commonurl+"/embassy/getRegisterEventListDashboard2All" , queryParams)
      }


      getRegisterEventListDashboard2(event, category, paid,currentPage){
        // const queryParams = `?event=${event}&category=${category}&paid=${paid}`;
        // console.log(queryParams);
        var queryParams = {
          event : event,
          category : category,
          paid : paid,
          page:currentPage
        }
        return this.http.post(commonurl+"/embassy/getRegisterEventListDashboard2" , queryParams)
      }



      // getRegisterEventListDashboard(event, category, paid,currentPage)
      // {
      //  return this.http.get(commonurl+'/embassy/getUpcomingEvents1?event='+event+'&category='+category+'&paid='+paid+'&page='+currentPage);
      // }

      // getRegisterEventListDashboard2(event, category, paid,currentPage)
      // {
      //  return this.http.get(commonurl+'/embassy/getUpcomingEvents1?event='+event+'&category='+category+'&paid='+paid+'&page='+currentPage);
      // }

  
      getnoOfEvents()
      {
        return this.http.get(commonurl+'/embassy/getnoOfEvents')
      }
    

      updatepassword(objPwd)
      {
        return this.http.post(commonurl+'/embassy/updatepassword',objPwd)
      }
      getoldpwds(values)
      {
       return this.http.post(commonurl+'/embassy/getoldpwds',values);
      }
      getEventListSearch()
      {
        return this.http.get(commonurl+'/embassy/getEventListSearch');
      }

      
  sendMessageNotifaction(messagess: string){
    console.log("hai email");
    this.subjectObjs.next({ email_status: messagess });
  }
  getMessageNotifaction(){
    return this.subjectObjs.asObservable();
}

getEventBasedCategory(id){
  return this.http.get(commonurl+'/embassy/getEventBasedCategory?id='+id);
}



//abhi


getDashboardNoOfCount(id)
{
  console.log(id);
  return this.http.get(commonurl+'/embassy/getDashboardNoOfCount/'+id);

}

getParticularRoles(val){
  return this.http.post(commonurl+'/embassy/getParticularRoles',val);
}

activeEmailVerified(val)
{
return this.http.post(commonurl+'/embassy/activeEmailVerified',val)
}


}

